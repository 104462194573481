import React, { Component } from 'react'

export class Cover extends Component {
    render() {
        return (
            <div className="coverimg">
                <img src="https://warpkings.com/images/crown.jpg"></img>
            </div>
        )
    }
}

export default Cover
