import React, { Component } from 'react'

export class footer extends Component {
    render() {
        return (      
    <div className="footer">
        <img src="https://warpkings.com/images/poweredbyDetourDigital.png" />
        <p>&#169; Warp Kings, 2021</p>
    </div>
        )
    }
}

export default footer
